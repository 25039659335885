<template>
  <div>
    <div class="banner">
    </div>
  </div>
  <div class="product">
    <div class="category-btns">
      <span
        v-for="(category, index) in categories"
        :key="index"
        class="category-btn"
        :class="{'category-btn--active': index === 0}"
      >
        {{category}}
      </span>
    </div>
    <div class="product-detail">
      <div class="product-detail--img">
        <div class="product-detail--img_bg">
          <div class="product-detail--img_pic">

          </div>
        </div>
      </div>
      <div class="product-detail--info">
        <p class="product-detail--info_title">卤猪心</p>
        <p class="product-detail--info_subtitle">色亮质鲜，卤味醇厚</p>
        <p class="product-detail--info_desc">产品规格：500g*20袋/件 </p>
        <p class="product-detail--info_desc">储存条件：冷冻</p>
        <p class="product-detail--info_desc">保质期：12个月</p>
        <p class="product-detail--info_desc">生产日期：见封口处</p>
      </div>
    </div>
    <div class="product-carousel">
      <div class="news-content">
        <div
          class="new-content-arrow new-content-arrow--left"
          @click="scrollNews('left')"
        >
          <ZcIconArrowDown :size="30" />
        </div>
        <div
          class="new-content-arrow new-content-arrow--right"
          @click="scrollNews('right')"
        >
          <ZcIconArrowDown :size="30" />
        </div>
        <div
          class="news-content-scroll"
          ref="news"
        >
          <template
            v-for="(item, index) in products"
            :key="index"
          >
            <div
              :ref="'newsItem'+index"
              class="news-content--item"
              :class="{'news-content--item_active': item.isActive}"
            >
              <div
                class="news-content--item_img"
                :style="{'background-image': 'url('+item.imgSrc+')'}"
              ></div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZcIconArrowDown from '@/components/ZcIconArrowDown.vue'
export default {
  name: "about",
  components: {
    ZcIconArrowDown
  },
  data() {
    return {
      categories: ['卤味产品', '水发货产品', '牛羊肉产品', '畅销产品'],
      products: [
        {
          imgSrc: require('@/assets/images/product-food/food1.png'),
          isActive: false
        },
        {
          imgSrc: require('@/assets/images/product-food/food2.png'),
          isActive: false
        },
        {
          imgSrc: require('@/assets/images/product-food/food3.png'),
          isActive: true
        },
        {
          imgSrc: require('@/assets/images/product-food/food2.png'),
          isActive: false
        },
        {
          imgSrc: require('@/assets/images/product-food/food2.png'),
          isActive: false
        },
        {
          imgSrc: require('@/assets/images/product-food/food2.png'),
          isActive: false
        }
      ],
      timer: null
    }
  },
  methods: {
    scrollNews(direction) {
      this.$refs.news.scrollLeft += direction === 'left' ? -470 : 470
      let half = window.screen.width / 2
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.products.forEach((item, index) => {
          let { left, right } = this.$refs['newsItem' + index].getBoundingClientRect()
          item.isActive = false
          if (left < half && right >= half) {
            item.isActive = true
          }
        })
      }, 500)
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  height: 36.5vw;
  background-image: url('../../../assets/images/product-food/banner.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.product {
  .category-btns {
    display: flex;
    justify-content: space-between;
    width: 62vw;
    margin: 0 auto;
    padding-top: 4.9rem;
    .category-btn {
      font-size: 2.4rem;
      color: #4d4c4c;
      background-color: #ffffff;
      padding: 1.5rem 3.8rem;
      box-shadow: 0rem 0rem 2rem 0rem rgba(186, 186, 186, 0.49);
    }
    .category-btn--active {
      color: #ffffff;
      background-color: #e61d0e;
    }
  }
  .product-detail {
    display: flex;
    margin-top: 6vw;
    padding: 0 16.5vw;
    .product-detail--img {
      width: 44vw;
      padding-top: 7.9vw;
      .product-detail--img_bg {
        position: relative;
        width: 31vw;
        height: 42vw;
        background-color: #e53a2e;
        .product-detail--img_pic {
          position: absolute;
          left: 7.3vw;
          bottom: 5.7vw;
          width: 36.8vw;
          height: 44vw;
          background-position: center center;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url('../../../assets/images/product-food/bg1.png');
        }
      }
    }
  }
  .product-detail--info {
    flex: 1;
    margin-top: 15.5vw;
    margin-left: 9vw;
    .product-detail--info_title {
      width: 21rem;
      height: 6rem;
      line-height: 6rem;
      color: #e53a2e;
      font-size: 3rem;
      font-weight: bold;
      font-weight: 500;
      margin-bottom: 3rem;
      background-position: center center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url('../../../assets/images/product-food/zhican.png');
    }
    .product-detail--info_subtitle {
      color: #4d4d4d;
      font-size: 2.2rem;
      margin-bottom: 2.2rem;
    }
    .product-detail--info_desc {
      color: #4d4d4d;
      font-size: 1.8rem;
      margin-bottom: 1.8rem;
    }
  }
}
.news-content {
  position: relative;
  margin-top: 7.8rem;
  padding-top: 2.2rem;
  .news-content-scroll {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    padding-top: 5.4rem;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  .new-content-arrow {
    position: absolute;
    top: 50%;
    z-index: 100;
    transform: translateY(-50%);
    font-size: 3.5rem;
    width: 4.4rem;
    height: 4.4rem;
    line-height: 4.4rem;
    text-align: center;
    border: 1px dotted white;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
  .new-content-arrow--left {
    left: 16px;
    svg {
      transform: translateX(-2px) rotate(90deg);
    }
  }
  .new-content-arrow--right {
    right: 16px;
    svg {
      transform: translateX(2px) rotate(-90deg);
    }
  }
  .news-content--item {
    display: inline-block;
    margin-right: 2.44rem;
    vertical-align: top;
    &:first-child {
      margin-left: 2.44rem;
    }
    .news-content--item_img {
      width: 15.6vw;
      height: 15.6vw;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .news-content--item_active {
    transform: translateY(-5.4rem);
    transform-origin: center center;
    .news-content--item_img {
      width: 21.8vw;
      height: 21.8vw;
    }
  }
}
</style>
